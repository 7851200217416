// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listposts-js": () => import("./../../../src/pages/listposts.js" /* webpackChunkName: "component---src-pages-listposts-js" */),
  "component---src-pages-listtags-js": () => import("./../../../src/pages/listtags.js" /* webpackChunkName: "component---src-pages-listtags-js" */),
  "component---src-pages-myinfo-js": () => import("./../../../src/pages/myinfo.js" /* webpackChunkName: "component---src-pages-myinfo-js" */),
  "component---src-pages-searchposts-js": () => import("./../../../src/pages/searchposts.js" /* webpackChunkName: "component---src-pages-searchposts-js" */),
  "component---src-templates-categories-template-js": () => import("./../../../src/templates/categories-template.js" /* webpackChunkName: "component---src-templates-categories-template-js" */),
  "component---src-templates-post-list-pagnition-template-js": () => import("./../../../src/templates/post-list-pagnition-template.js" /* webpackChunkName: "component---src-templates-post-list-pagnition-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

